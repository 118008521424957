import { FC, useCallback, useState } from "react";
import { motion } from "framer-motion";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { ReactComponent as Logo } from "../img/logo.svg";
import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { PublicKey } from "@solana/web3.js";
import { Icon } from "@iconify/react";
import * as anchor from '@project-serum/anchor';
import { AnchorProvider, Program } from "@project-serum/anchor";
import { IDL, AnchorEscrow } from "../program/anchorEscrow";
import { apyTokenId, escrow, programId } from "../program/constants";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { getOrCreateAssociatedTokenAccount } from "../utils/getOrCreateAssociatedTokenAccount";
import { Alert } from "react-daisyui";

const Home: FC = () => {
    const { connection } = useConnection();
    const { publicKey, signTransaction } = useWallet();
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");


    const wallet = useAnchorWallet();
    // 
    const apyToken = new PublicKey(apyTokenId);
    const escrowAccount = new PublicKey(escrow);

    const getProvider = function () {
        return new AnchorProvider(connection, wallet as anchor.Wallet, { "preflightCommitment": "processed" })
    }

    const setErrorWithTime = useCallback(async (_errorMessage) => {
        setErrorMessage(_errorMessage);

        setTimeout(() => {
            setErrorMessage("");
        }, 3000);
    }, [setErrorMessage]);

    const setInfoWithTime = useCallback(async (_infoMessage) => {
        setInfoMessage(_infoMessage);

        setTimeout(() => {
            setInfoMessage("");
        }, 3000);
    }, [setInfoMessage]);

    const onClick = useCallback(async () => {
        try {

            if (!publicKey || !signTransaction) throw new WalletNotConnectedError();

            await setLoading(true);

            anchor.setProvider(getProvider());

            const program = new Program<AnchorEscrow>(IDL, new PublicKey(programId), anchor.getProvider());

            const tokenAccount = await getOrCreateAssociatedTokenAccount(
                connection,
                publicKey,
                apyToken,
                publicKey,
                signTransaction
            )

            const [_vault_account_pda] = await PublicKey.findProgramAddress(
                [Buffer.from(anchor.utils.bytes.utf8.encode("token-seed"))],
                program.programId
            );

            const [_vault_authority_pda] = await PublicKey.findProgramAddress(
                [Buffer.from(anchor.utils.bytes.utf8.encode("escrow"))],
                program.programId
            );

            const _escrowAccount = await program.account.escrowAccount.fetch(
                escrowAccount
            );

            console.log(tokenAccount.amount);
            if (tokenAccount.amount >= _escrowAccount.limitAmount.toNumber()) {
                // eslint-disable-next-line
                throw ({ "message": "You already bought the maximum amount of aliens posible" });
            }

            if(!_escrowAccount.active){
                // eslint-disable-next-line
                throw ({ "message": "The reservation is not available." });
            }

            await program.rpc.buy({
                accounts: {
                    takerSigner: publicKey,
                    taker: publicKey,
                    takerReceiveTokenAccount: tokenAccount.address,
                    initializerDepositTokenAccount: _escrowAccount.initializerDepositTokenAccount,
                    initializer: _escrowAccount.initializerKey,
                    escrowAccount: escrowAccount,
                    vaultAccount: _vault_account_pda,
                    vaultAuthority: _vault_authority_pda,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    systemProgram: anchor.web3.SystemProgram.programId
                },
                signers: []
            });

            await setLoading(false);

            setInfoWithTime("You buy 1 Apy Alien 👽");
        } catch (error: any) {
            if (error.message)
                setErrorWithTime(error.message);

            console.log(error);
            await setLoading(false);
        }
        // eslint-disable-next-line
    }, [setLoading, signTransaction, publicKey, setErrorWithTime, setInfoWithTime]);

    const onClickCode = useCallback(async () => {
        try {

            if (!publicKey || !signTransaction) throw new WalletNotConnectedError();

            await setLoading(true);

            anchor.setProvider(getProvider());

            const program = new Program<AnchorEscrow>(IDL, new PublicKey(programId), anchor.getProvider());

            const tokenAccount = await getOrCreateAssociatedTokenAccount(
                connection,
                publicKey,
                apyToken,
                publicKey,
                signTransaction
            )

            const [_vault_account_pda] = await PublicKey.findProgramAddress(
                [Buffer.from(anchor.utils.bytes.utf8.encode("token-seed"))],
                program.programId
            );

            const [_vault_authority_pda] = await PublicKey.findProgramAddress(
                [Buffer.from(anchor.utils.bytes.utf8.encode("escrow"))],
                program.programId
            );

            const _escrowAccount = await program.account.escrowAccount.fetch(
                escrowAccount
            );

            if (tokenAccount.amount >= _escrowAccount.limitAmount.toNumber()) {
                // eslint-disable-next-line
                throw ({ "message": "You already bought the maximum amount of aliens posible." });
            }

            if(!_escrowAccount.active){
                // eslint-disable-next-line
                throw ({ "message": "The reservation is not available." });
            }

            const _allReferralAccount = await program.account.referralAccount.all();

            let referral: PublicKey | null = null;
            let referralAccount: PublicKey | null = null;
            _allReferralAccount.forEach(element => {
                if (element.account.referralKey.toBase58() === code) {
                    referralAccount = element.publicKey;
                    referral = element.account.referralKey;
                }
            });

            if (referral !== null && referralAccount !== null) {
                await program.rpc.buyReferral({
                    accounts: {
                        takerSigner: publicKey,
                        taker: publicKey,
                        takerReceiveTokenAccount: tokenAccount.address,
                        referral: referral,
                        referralAccount: referralAccount,
                        initializerDepositTokenAccount: _escrowAccount.initializerDepositTokenAccount,
                        initializer: _escrowAccount.initializerKey,
                        escrowAccount: escrowAccount,
                        vaultAccount: _vault_account_pda,
                        vaultAuthority: _vault_authority_pda,
                        tokenProgram: TOKEN_PROGRAM_ID,
                        systemProgram: anchor.web3.SystemProgram.programId
                    },
                    signers: []
                });

                setInfoWithTime("You buy 1 Apy Alien 👽");
            } else {
                setErrorWithTime("That code doesn't exists");
            }

            await setLoading(false);
            await setCode("");
        } catch (error: any) {
            if (error.message)
                setErrorWithTime(error.message);

            console.log(error);
            await setLoading(false);
        }

        // eslint-disable-next-line
    }, [code, publicKey, setErrorWithTime, setInfoWithTime]);

    return (
        <div>
            <div className="absolute inset-x-0 top-0 z-50 w-full transition duration-300 ease-in-out bg-transparent border-b border-transparent text-primary-content navbar">

                <div className="pl-4 flex items-center">
                    <a className="toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-white" href="/">
                        <Logo className="h-8 fill-current inline mt-[-5px]" /> APY ALIENS
                    </a>
                </div>

                <div className="flex-grow justify-end lg:items-center lg:w-auto lg:mt-0 bg-transparent text-black p-4 lg:p-0 z-2" id="nav-content">
                    <ul className="list-reset justify-end flex items-center">
                        <WalletMultiButton className="btn btn-primary" />
                    </ul>
                </div>
            </div>

            <div className="z-20 mt-20 fixed w-full">
                <Alert className={`w-full alert alert-error shadow-lg ${errorMessage !== "" ? "" : "invisible absolute"}`}
                    status="error"
                    icon={<Icon icon="bxs:error-circle" width={16} height={16} />}
                    onClick={() => { setErrorMessage("") }}
                >
                    {errorMessage}
                </Alert>
                <Alert className={`w-full alert alert-info shadow-lg ${infoMessage !== "" ? "" : "invisible absolute"}`}
                    status="info"
                    icon={<Icon icon="ic:round-security-update-good" width={16} height={16} />}
                    onClick={() => { setInfoMessage("") }}
                >
                    {infoMessage}
                </Alert>
            </div>

            <div
                className="relative md:min-h-screen min-h-max pt-0 mt-0 antialiased hero from-primary to-secondary text-primary-content"
                id="home"
            >
                <div className="md:mt-0 mt-20 text-justify hero-content text-neutral-content flex-wrap space-between space-x w-full place-content-around">
                    <div className="max-w-md text-center">
                        <div className="bg-red-500 origin-top float-right mt-8 ml-[-50px] w-20 text-center absolute" style={{ transform: 'translateX(50%) rotate(310deg)' }} >
                            <div >50% Off</div>
                        </div>
                        <motion.img
                            animate={{ scale: [0.5, 1] }}
                            transition={{ duration: 0.5 }}
                            src={require('../img/alien-img.gif')}
                            className="fill-current lg:flex"
                            style={{ width: '300px' }}
                        >
                        </motion.img>
                    </div>
                    <div className="max-w-md text-center ">
                        <motion.div
                            animate={{ scale: [0.5, 1] }}
                            transition={{ duration: 0.5 }}
                        >
                            <h1 className="mb-5 text-5xl font-bold ">Reserve your alien</h1>
                            <p className="mb-5">Buy the apy alien NFT with discount BEFORE to the public sale</p>
                            <div className="form-control mb-8">
                                <div className="input-group content-center text-center items-center justify-center flex">
                                    <input type="text" placeholder="Referral Code..." className="input input-bordered" disabled={!publicKey} value={code} onChange={e => setCode(e.target.value)} />
                                    <button className="btn btn-square" onClick={!loading ? onClickCode : () => { }} disabled={!publicKey}>
                                        <Icon icon={loading ? "eos-icons:bubble-loading" : "akar-icons:send"} width={16} height={16} />
                                    </button>
                                </div>
                            </div>
                            <button className="btn btn-primary rounded-full gap-2" onClick={!loading ? onClick : () => { }} disabled={!publicKey}>
                                Reserve without code
                                <Icon icon={loading ? "eos-icons:bubble-loading" : "akar-icons:send"} width={16} height={16} />
                            </button>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;